import React from 'react'
import aboutImg from '../img/about.svg'
import { FaCheckCircle } from 'react-icons/fa'

export default function About() {
    return (
        <section className="about" id="about">
            <div className="container">
                <div className="text">
                    <h2><span>Creativity</span> is what<br/>distinguish us</h2>
                    <h3>WE ARE COMMITTED TO PROVIDING OUR CUSTOMERS WITH EXCEPTIONAL SERVICE.</h3>
                    <p>Although a young company, we have more than 7 years of experience in the field of web development and design.
                    Over time, we have acquired new knowledge in order to be in continuity of
                    constant events and technological changes, as the market demands. We try to meet
                        the needs of our clients to the maximum for the purpose of mutual satisfaction.</p>
                    <ul>
                        <li><FaCheckCircle /> A very specific services available</li>
                        <li><FaCheckCircle /> Always rofessional approach</li>
                        <li><FaCheckCircle /> Adherence to deadlines</li>
                        <li><FaCheckCircle /> Both short & long engagement period</li>
                    </ul>
                </div>
                <div className="aboutImg">
                    <img src={aboutImg} alt="webium-solutions-about"/>
                </div>

            </div>
        </section>
    )
}
