import React from 'react'
import webDev from '../img/coding.svg'
import webDes from '../img/designer.svg'
import seo from '../img/seo.svg'
import hosting from '../img/data_hosting.svg'

export default function Services() {
    return (
        <section className="services" id="services">
            <div className="container">
                <h2>We shape<br />the perfect <span>solutions</span></h2>
                <p className="description">Through our services we try to cover all 
                our client's requirements. Not sure what you need? Take our advice.</p>
            </div>
            <div className="container servicesList">
                <div className="service">
                    <img src={webDev} alt="webium-solutions-web-development-service"/>
                    <h3><span>Web</span> Development</h3>
                    <p>For business promotion, every ambitious company today must have a well-crafted and secure website.</p>
                </div>
                <div className="service">
                    <img src={hosting} alt="webium-solutions-hosting-and-domain-service"/>
                    <h3><span>Hosting & Domain</span></h3>
                    <p>As an exclusive partner of a very successful hosting company we provide hosting & domain services too.</p>
                </div>
                <div className="service">
                    <img src={webDes} alt="webium-solutions-graphic-ui-design-service"/>
                    <h3><span>Graphic/UI</span> Design</h3>
                    <p>We are combining typography, photography, illustration and colors to improve overral user experience.</p>
                </div>
                <div className="service">
                    <img src={seo} alt="webium-solutions-seo-optimization-service"/>
                    <h3><span>Seo</span> Optimization</h3>
                    <p>Greater searchability and visibility are fundamental for taking company brand to the next level.</p>
                </div>
            </div>
        </section>
    )
}
