import React from 'react'
import Header from './components/Header'
import { BrowserRouter as Router } from 'react-router-dom'
import Welcome from './components/Welcome'
import About from './components/About'
import Services from './components/Services'
import Work from './components/Work'
import Contact from './components/Contact'
import Footer from './components/Footer'

export default function App() {
  return (
    <>
      <Router>
        <Header />
        <main>
          <Welcome />
          <About />
          <Services />
          <Work />
          <Contact />
        </main>
        <Footer />
      </Router>
    </>
  )
}

