import React from 'react'
import work from '../img/coding_.svg'
import htmlImg from '../img/html.svg'
import cssImg from '../img/css.svg'
import jsImg from '../img/javascript.svg'
import reactImg from '../img/react.svg'
import wpImg from '../img/wordpress.svg'
import psImg from '../img/photoshop.svg'

export default function Services() {
    return (
        <section className="work" id="work">
            <div className="container">
                <div className="text">
                    <h2><span>Experienced</span> work in technologies</h2>
                    <h3>Narrowing the scope of technologies in use we achieved - quality over quantity</h3>
                    <p>We have opted for a couple of technologies that we handle perfectly and
                        we stick to them. Over time our clients have become increasingly satisfied with the quality of our services and our capabilities.</p>
                    <div className="techs first">
                        <div className="tech">
                            <img src={htmlImg} alt="webium-solutions-html5" />
                            <h4>HTML5</h4>
                        </div>
                        <div className="tech">
                            <img src={cssImg} alt="webium-solutions-css3" />
                            <h4>CSS3</h4>
                        </div>
                        <div className="tech">
                            <img src={jsImg} alt="webium-solutions-javascript" />
                            <h4>JavaScript</h4>
                        </div>
                    </div>
                    <div className="techs">
                        <div className="tech">
                            <img src={reactImg} alt="webium-solutions-react" />
                            <h4>ReactJS</h4>
                        </div>
                        <div className="tech">
                            <img src={wpImg} alt="webium-solutions-wordpress" />
                            <h4>Wordpress</h4>
                        </div>
                        <div className="tech">
                            <img src={psImg} alt="webium-solutions-photoshop" />
                            <h4>Photoshop</h4>
                        </div>
                    </div>
                </div>
                <div className="workImg">
                    <img src={work} alt="webium-solutions-work" />
                </div>
            </div>
        </section>
    )
}
