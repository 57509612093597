import emailjs from 'emailjs-com';
import React, {useState} from 'react'

export default function Contact() {

    const [loader, setLoader] = useState(false);

    function sendEmail(e) {
        e.preventDefault();
        setLoader(true);

        emailjs.sendForm('service_br8g1fw', 'template_6nfxuvc', e.target, 'user_kQKN2qolrKI730WlEGuyo')
            .then((result) => {
                alert('Message sent successfully!');
                setLoader(false);
            }, (error) => {
                alert('Something went wrong. Please try again later.');
                setLoader(false);
            });
            e.target.reset();
    }

    return (
        <section className="contact" id="contact">
            <div className="container">
                <h2>We are<br /><span>available</span> for you</h2>
                <p className="description">If you have any questions or concerns about our services,
                in any time you can contact us by filling out the form below or directly by email.
                    We will do our best to answer you as soon as possible.</p>
            </div>
            <div className="container">
                <form method="post" className="contact-form" onSubmit={sendEmail}>
                    <div className="form-row">
                        <input type="text" placeholder="Your Name:" name="contact-name" required />
                        <input type="email" placeholder="Email Address:" name="contact-email" required />
                    </div>
                    <div className="form-row">
                        <textarea placeholder="Your message here..." name="contact-message" required></textarea>
                    </div>
                    <div className="form-row">
                        <input style={loader ? {opacity: '0.5'} : {}} type="submit" value="Send Message" />
                    </div>
                </form>
            </div>
        </section>
    )
}
