import React from 'react'

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="footerCell">
                    <h3>Contact Us</h3>
                    <p>Email: office@webiumsolutions.com</p>
                    <p>Phone: +381 61 244 51 41</p>
                    <p>Address: Konstantina Filozofa 3, 37000 Krusevac, Serbia</p>
                </div>
                <div className="footerCell aboutUs">
                    <h3>About us</h3>
                    <p>We are committed to providing our customers with exceptional service. 
                        More than 7 years of experience in the field of web development and design. 
                        Trying to meet the needs of our clients to the maximum for the purpose of mutual satisfaction.</p>
                </div>
            </div>
            <div className="copyright">
                <p>Copyright © Webium Solutions 2020 | All Rights Reserved</p>
            </div>
        </footer>
    )
}
