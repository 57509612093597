import React, { useState } from 'react'
import { Link } from 'react-scroll'
import logo from '../img/ws-logo.png'
import { FaBars } from 'react-icons/fa'
import { FaTimes } from 'react-icons/fa'

export default function Header() {
    const [header, setHeader] = useState(false)
    const changeHeader = () => {
        if(window.scrollY >= 150) {
            setHeader(true)
        } else {
            setHeader(false)
        }
    }
    window.addEventListener('scroll', changeHeader);

    const [open, setOpen] = useState(false)
    const toggle = () => {
        setOpen(!open)
    }

    return (
        <>
            <header className={header ? 'active' : 'header'}>
                <div className="container">
                    <div className="logo">
                        <Link to='/'><img src={logo} alt="webium-solutions-logo"/></Link>
                    </div>
                    <nav>
                        <Link
                            activeClass="current"
                            to="home"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                        >Home</Link>
                        <Link
                            activeClass="current"
                            to="about"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                        >About</Link>
                        <Link
                            activeClass="current"
                            to="services"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                        >Services</Link>
                        <Link
                            activeClass="current"
                            to="work"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                        >Work</Link>
                        <Link
                            activeClass="current"
                            to="contact"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                        >Contact</Link>
                    </nav>
                </div>
                <div className="sideMenuToggle" onClick={toggle}>
                    <FaBars />
                </div>
                {open &&
                    <aside>
                        <div className="asideClose" onClick={toggle}>
                            <FaTimes />
                        </div>
                        <Link onClick={toggle}
                            to="home"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                        >Home</Link>
                        <Link onClick={toggle}
                            to="about"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                        >About</Link>
                        <Link onClick={toggle}
                            to="services"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                        >Services</Link>
                        <Link onClick={toggle}
                            to="work"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                        >Work</Link>
                        <Link onClick={toggle}
                            to="contact"
                            spy={true}
                            smooth={true}
                            offset={-90}
                            duration={500}
                        >Contact</Link>
                    </aside>
                }
            </header>
        </>
    )
}

