import React from 'react'
import { Link } from 'react-scroll'
import transWave from '../img/right-curved-trans.png'
import blueWave from '../img/right-curved-bg.png'
import { FaChevronRight } from 'react-icons/fa'

export default function Welcome() {
    return (
        <section className="welcome" id="home">
            <div className="container">
                <div className="sub-title">
                    Welcome to Webium Solutions
                    </div>
                <h1>
                    Web Development <span>&</span><br />Design Agency
                    </h1>
                <p className="description">
                    We turn your idea into reality in order to <span>improve your business growth!</span>
                </p>
                <Link
                    activeClass="current"
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-90}
                    duration={500}
                >Our Services <FaChevronRight /></Link>
            </div>
            <img src={transWave} className="transWave" alt="webium-solutions-wave-transparent" />
            <img src={blueWave} className="blueWave" alt="webium-solutions-wave-blue" />
        </section>
    )
}
